// eslint-disable-next-line no-restricted-imports
import { captureException as sentryCaptureException } from '@sentry/nextjs';

class CustomError extends Error {
  payload?: any;

  constructor(message?: string, payload?: any) {
    super(message);
    if (payload) {
      this.payload = payload;
    }
  }
}

function getStringifiedError(exception: any): string {
  try {
    if (typeof exception === 'string') {
      return exception;
    }
    const stringifiedError = JSON.stringify(exception);
    return stringifiedError !== '{}' ? stringifiedError : '';
  } catch {
    return '';
  }
}

function getErrorException(exception: any): CustomError {
  if (exception instanceof Error) {
    return exception;
  }

  return new CustomError(exception?.message || 'error', exception);
}

/**
 * if exception object has additional/extra property,
 * capture it as sentry extra `error` context
 * if exception is not an instance of Error,
 * wrap it within Error object to avoid sentry Non-Error exception
 * @param exception
 */
export function captureException(exception: any) {
  const extraError = getStringifiedError(exception);
  const error = getErrorException(exception);

  if (extraError) {
    sentryCaptureException(error, {
      extra: { error: extraError },
    });
  } else {
    sentryCaptureException(error);
  }
}

var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8ac35c43751a3eb310708844219b32550638b188"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import get from 'lodash/get';
import nth from 'lodash/nth';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const netlifyBuildId = process.env.BUILD_ID;
const netlifyCommitId = process.env.COMMIT_REF;

const removeResetToken = (url) => {
  const params = new URLSearchParams(url);
  params.delete('resetToken');
  return decodeURIComponent(decodeURI(params)).toString();
};

/**
 * @param {Sentry.StackFrame|undefined} frame
 * @returns {boolean}
 */
const isContentsquareFrame = (frame) => {
  const filename = get(frame, 'filename', '');

  return typeof filename === 'string' && (filename.includes('uxa/') || filename.includes('contentsquare.net'));
};

Sentry.init({
  dsn: SENTRY_DSN || 'https://403e3a13f9474550bb9f4860ab829075@o29118.ingest.sentry.io/6105864',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production' ? 0.5 : 1.0,
  environment: process.env.NEXT_PUBLIC_DEPLOY_ENV,
  // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
  ignoreErrors: [
    `fbq is not defined`,
    `Can't find variable: fbq`,
    `TileServices Error: User email already exists`, // Tile Services
    `null is not an object (evaluating 'e.newScriptElement.parentNode.removeChild')`, // Taboola
    `undefined is not an object (evaluating 'r.DomainData')`, // OneTrust
    `Failed to execute 'sendBeacon' on 'Navigator': sendBeacon() with a Blob whose type is not any of the CORS-safelisted values for the Content-Type request header is disabled temporarily. See http://crbug.com/490015 for details.`, // Cloudflare Insights
    `No currency found!`, // puppeteer script from (presumably) external scraper
    // SyntaxError related
    // excluded from sentry to free up bandwith
    'Unexpected token',
    'Unexpected identifier',
    'Unexpected end of input',
    'Invalid or unexpected token',
    'Invalid regular expression',
  ],
  // A list of strings or regex patterns that match error URLs that should not be sent to Sentry
  denyUrls: [
    /contentsquare\.net/,
    /cdn\.cookielaw\.org/,
    /static\.cloudflareinsights\.com/,
    /ninetailed\.co/,
    /launchdarkly\.com/,
    /turnto\.com/,
    /taboola\.com/,
    /braze\.com/,
    /onetrust\.com/,
    /google-analytics\.com/,
    /doubleclick\.net/,
    /googleadservices\.com/,
    /amazon-adsystem\.com/,
    /appboycdn\.com/,
    /facebook\.net/,
    /facebook\.com/,
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeBreadcrumb(breadcrumb) {
    const navigationBreadcrumb = breadcrumb;
    if (navigationBreadcrumb?.category === 'navigation') {
      if (navigationBreadcrumb?.data?.from) {
        navigationBreadcrumb.data.from = removeResetToken(navigationBreadcrumb.data.from);
      }
      if (navigationBreadcrumb?.data?.to) {
        navigationBreadcrumb.data.to = removeResetToken(navigationBreadcrumb.data.to);
      }
    }
    return navigationBreadcrumb;
  },
  beforeSend(event) {
    const frames = get(event, 'exception.values[0].stacktrace.frames', []);
    const lastFrame = nth(frames, -1);
    const secondLastFrame = nth(frames, -2);

    if (isContentsquareFrame(lastFrame) || isContentsquareFrame(secondLastFrame)) {
      return null;
    }

    return event;
  },
  dist: netlifyBuildId,
  release: netlifyCommitId, // https://github.com/getsentry/sentry-javascript/issues/5734
});
